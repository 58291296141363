import { Toolbar, ToolbarButton, ToolbarGroup } from "./Toolbar";
import { MarkToolbarButton } from "./MarkToolbarButton";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import Icon from "../../common/Icon";
import { TurnIntoDropDownMenu } from "./TurnIntoDropDownMenu";
import { IndentToolbarButton } from "./IndentToolbarButton";
import { OutdentToolbarButton } from "./OutdentToolbarButton";
import { LinkToolbarButton } from "./LinkToolbarButton";
import { emptyContent, odoTagContent } from "odo";
import useAddComment from "../../../lib/plate/plugins/useAddComment";
import {
  TElement,
  findNode,
  isCollapsed,
  isElement,
  useEditorRef,
} from "@udecode/plate-common";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { useState } from "react";
import AddTableView from "./AddTableView";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Overlay from "components/common/containers/overlays/Overlay";
import KnowledgeBaseButton from "./KnowledgeBaseMenuButton";
import { MarkdownEncoder } from "odo";
import { toast } from "react-toastify";

interface FixedToolbarProps {
  aiEnabled?: boolean;
  commentsEnabled?: boolean;
}

const FixedToolbar = ({ aiEnabled = true, commentsEnabled = true }) => {
  const addComment = useAddComment();
  const editor = useEditorRef();
  const [tableCreationOpen, setTableCreationOpen] = useState(false);
  const unindentableNode = findNode<TElement>(editor, {
    match: (node) =>
      isElement(node) && (node.type !== "p" || !("listStyleType" in node)),
  });
  const { isStaff, isWriter, hasRegenerate } = useAuthenticatedUser();

  const handleCopyMarkdown = () => {
    let elements: any[];
    if (editor.selection && !isCollapsed(editor.selection)) {
      elements = Array.from(
        editor.nodes({
          at: editor.selection,
          match: (element, path) => {
            return path.length === 1;
          },
        })
      ).map(([element]) => element);
    } else {
      elements = editor.children;
    }
    const encoder = new MarkdownEncoder(elements);
    const markdown = encoder.encode();
    navigator.clipboard.writeText(markdown);
    toast.success("Markdown Copied Successfully");
  };

  return (
    <Toolbar className="border-b shrink-0">
      <TurnIntoDropDownMenu />
      <ToolbarGroup>
        <MarkToolbarButton nodeType={MARK_BOLD} tooltip="Bold (⌘B)">
          <Icon name="bold" />
        </MarkToolbarButton>
        <MarkToolbarButton nodeType={MARK_ITALIC} tooltip="Italic (⌘I)">
          <Icon name="italic" />
        </MarkToolbarButton>
        <MarkToolbarButton nodeType={MARK_UNDERLINE} tooltip="Underline (⌘U)">
          <Icon name="underline" />
        </MarkToolbarButton>
        <MarkToolbarButton
          nodeType={MARK_STRIKETHROUGH}
          tooltip="Strikethrough (⌘⇧X)"
        >
          <Icon name="strikethrough" />
        </MarkToolbarButton>
        <MarkToolbarButton nodeType={MARK_HIGHLIGHT} tooltip="Highlight (⌘⌥H)">
          <Icon name="highlighter-line" />
        </MarkToolbarButton>
      </ToolbarGroup>
      <ToolbarGroup>
        <IndentToolbarButton disabled={!!unindentableNode} />
        <OutdentToolbarButton disabled={!!unindentableNode} />
      </ToolbarGroup>
      <ToolbarGroup>
        <LinkToolbarButton />
        <ToolbarButton
          tooltip="Insert Table"
          onClick={() => setTableCreationOpen(true)}
        >
          <Icon name="table" />
        </ToolbarButton>
      </ToolbarGroup>
      {commentsEnabled && (
        <ToolbarGroup>
          <ToolbarButton
            tooltip="Comment (⌘⇧M)"
            onClick={() => addComment(emptyContent)}
            disabled={isCollapsed(editor.selection)}
          >
            <Icon name="comment-plus" />
          </ToolbarButton>
        </ToolbarGroup>
      )}
      {aiEnabled && (hasRegenerate || isWriter) && (
        <>
          {/* <ToolbarGroup>
            <RegenerateButton />
          </ToolbarGroup> */}
          <ToolbarGroup>
            <ToolbarButton
              tooltip="Tag Odo in a comment (⌘⇧O)"
              onClick={() => addComment(odoTagContent)}
              disabled={isCollapsed(editor.selection)}
              className="px-md"
              variant="ai"
            >
              @Odo
            </ToolbarButton>
          </ToolbarGroup>
        </>
      )}
      <div className="grow border-l" />
      {isWriter && (
        <ToolbarGroup>
          <ToolbarButton onClick={handleCopyMarkdown}>
            <Icon name="copy" className="admin text-primary" />
          </ToolbarButton>
        </ToolbarGroup>
      )}
      <Overlay
        title="Add Table"
        maxWidth={360}
        open={tableCreationOpen}
        onClose={() => setTableCreationOpen(false)}
      >
        <AddTableView />
      </Overlay>
    </Toolbar>
  );
};

export default FixedToolbar;
