import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import ContentLibraryProjectDetails from "components/content-library/ContentLibraryDetails";
import ContentLibraryNavigation from "components/content-library/ContentLibraryNavigation";
import useContentLibrary from "hooks/useContentLibrary";
import { FC } from "react";

const ContentLibraryPanel: FC = () => {
  const {
    activeCategory,
    activeItems,
    activePaginatedData,
    selectedItem,
    setActiveCategory,
    setSelectedItemSaveState,
    setSelectedItem,
    addItem,
    updateItem,
    removeItem,
  } = useContentLibrary(undefined, undefined);
  return (
    <Columns className="w-full grow">
      <ContentLibraryNavigation
        items={activeItems}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        paginatedData={activePaginatedData}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        addItem={addItem}
      />
      {selectedItem && (
        <Overlay onClose={() => setSelectedItem(null)} className="w-full">
          <Columns>
            <ContentLibraryProjectDetails
              activeCategory={activeCategory}
              selectedItem={selectedItem}
              setSelectedItemSaveState={setSelectedItemSaveState}
              updateItem={updateItem}
              removeItem={removeItem}
            />
          </Columns>
        </Overlay>
      )}
    </Columns>
  );
};

export default ContentLibraryPanel;
