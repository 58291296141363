import { cn } from "lib/utils";
import {
  FC,
  HTMLProps,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Spinner from "../Spinner";
import { PaginatedData } from "hooks/usePaginatedData";
import { message_from_exception } from "utils";
import Input from "../forms/Input";
import { debounce } from "lodash";
import Toggle from "../forms/Toggle";
import Rows from "./Rows";
import CenteredContainer from "./CenteredContainer";
import Columns from "./Columns";
import ErrorView from "./ErrorView";
import React from "react";

type PaginatedTableVariant = "default" | "without-padding";

interface Column {
  name?: string;
  size?: "auto" | "min" | number;
  center?: boolean;
}

interface CellProps extends HTMLProps<HTMLDivElement> {
  center?: boolean;
}

interface RowProps {
  children?: React.ReactNode;
}

interface PaginatedTableViewProps<T> {
  results: T[] | null;
  paginatedData: PaginatedData;
  columns: Column[] | number;
  rowHeight?: number;
  rowSeparators?: boolean;
  renderRow: (
    item: T,
    Cell: FC<CellProps>,
    Row: FC<RowProps>,
    index: number
  ) => ReactElement<RowProps>;
  onSelect?: (item: T) => void;
  searchable?: boolean;
  className?: string;
  isSelected?: (item: T) => boolean;
  noResultsText?: string;
  variant?: PaginatedTableVariant;
}

const PaginatedTableView = <T,>({
  results,
  paginatedData,
  renderRow,
  rowSeparators,
  onSelect,
  columns,
  rowHeight,
  searchable,
  className,
  isSelected,
  noResultsText = "No results",
  variant = "default",
}: PaginatedTableViewProps<T>) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const { loadNext, loadPrevious, error, setSearch } = paginatedData;
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [pendingSearch, setPendingSearch] = useState<string>("");
  const debouncedSearch = useRef(
    debounce((search: string) => {
      setSearch(search);
    }, 500)
  );

  useEffect(() => {
    debouncedSearch.current(pendingSearch);
  }, [debouncedSearch, pendingSearch, setSearch]);

  useEffect(() => {
    const scrollable = scrollableRef.current;
    if (scrollable) {
      const checkScoll = (div: HTMLDivElement) => {
        const scrollFromTop = div.scrollTop;
        if (scrollFromTop < 200 && typeof loadPrevious === "function") {
          loadPrevious();
        }

        const scrollFromBottom =
          div.scrollHeight - div.clientHeight - scrollFromTop;

        if (scrollFromBottom < 200 && typeof loadNext === "function") {
          loadNext();
        }
      };

      const handleScroll = (e: any) => {
        checkScoll(e.target);
      };
      checkScoll(scrollable);

      scrollable.addEventListener("scroll", handleScroll);
      return () => {
        scrollable.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loadNext, loadPrevious, scrollableRef]);

  if (!!error) {
    return <ErrorView title="Error Loading" error={error} />;
  } else if (results === null) {
    return (
      <CenteredContainer className={className}>
        <Spinner text="Loading..." />
      </CenteredContainer>
    );
  } else {
    return (
      <Rows className={className}>
        {searchable || !!paginatedData.filters ? (
          <Columns
            className={cn(
              "gap-md items-center shrink-0",
              variant === "without-padding" && "border-b"
            )}
          >
            {searchable && (
              <Input
                icon="magnifying-glass"
                placeholder="Search"
                className="m-sm sticky"
                variant="accent"
                value={pendingSearch}
                valueClearable={true}
                onChange={(e) => {
                  setPendingSearch(e.target.value);
                }}
              />
            )}
            {Object.entries(paginatedData.filters ?? {}).map(
              ([name, { value, setValue }]) => (
                <Toggle
                  key={name}
                  text={name}
                  on={value}
                  onToggle={(on) => {
                    setValue(on);
                  }}
                />
              )
            )}
          </Columns>
        ) : null}
        <Rows className="overflow-auto border-b" ref={scrollableRef}>
          <div
            className="grid"
            style={{
              gridTemplateColumns: gridTemplateColumnsFromColumns(columns),
            }}
          >
            {Array.isArray(columns) &&
              columns.find((col) => col.name) &&
              columns.map((col) => (
                <HeaderCell
                  key={col.name}
                  className={col.center === true ? "justify-center" : ""}
                >
                  {col.name}
                </HeaderCell>
              ))}
            {loadPrevious && (
              <div className="col-span-full">
                <Spinner className="mx-auto y-md" />
              </div>
            )}
            {!!error && (
              <div className="ol-span-full text-destructive">
                {message_from_exception(error)}
              </div>
            )}
            {results.length === 0 && (
              <div className="col-span-full text-secondary text-center py-2m">
                {noResultsText}
              </div>
            )}
            {results.map((value, i) => {
              return renderRow(
                value,
                wrapCell({
                  onClick: onSelect ? () => onSelect(value) : undefined,
                  onMouseEnter: onSelect ? () => setHoveredRow(i) : undefined,
                  onMouseLeave: onSelect
                    ? () => setHoveredRow(null)
                    : undefined,
                  style: {
                    height: rowHeight ? `${rowHeight}px` : undefined,
                  },
                  className: cn(
                    hoveredRow === i || !!isSelected?.(value)
                      ? "bg-background-selected"
                      : "",
                    onSelect ? "cursor-pointer" : "",
                    rowSeparators && i !== results.length - 1 ? "border-b" : ""
                  ),
                }),
                Row,
                i
              );
            })}
            {typeof loadNext === "function" && (
              <div className="col-span-full" onClick={loadNext}>
                <Spinner className="mx-auto y-md" />
              </div>
            )}
            {typeof loadNext === "string" && (
              <div className="col-span-full text-destructive text-center py-2m">
                Error: {loadNext}
              </div>
            )}
          </div>
        </Rows>
      </Rows>
    );
  }
};

/**
 * A special row component that takes a key and children
 *
 * It applies the key to each child and then returns the children
 */
const Row = ({ children }: RowProps) => {
  return <>{children}</>;
};

const wrapCell = ({
  className: parentClassName,
  ...parentProps
}: HTMLProps<HTMLDivElement>) => {
  const Cell: FC<CellProps> = ({
    className,
    center,
    children,
    ...childProps
  }) => {
    return (
      <div
        {...parentProps}
        {...childProps}
        className={cn(
          "px-2m py-sm z-auto flex items-center bg-background",
          center && "justify-center",
          className,
          parentClassName
        )}
      >
        {children}
      </div>
    );
  };

  return Cell;
};

const HeaderCell = wrapCell({
  className:
    "text-secondary text-sm border-b pb-sm font-medium sticky top-0 bg-background",
});

const gridTemplateColumnsFromColumns = (columns: Column[] | number) => {
  if (typeof columns === "number") {
    return `repeat(${columns}, auto)`;
  }
  return columns
    .map((col) => {
      switch (col.size ?? "auto") {
        case "auto":
          return "auto";
        case "min":
          return "fit-content(100%)";
        default:
          return `${col.size}px`;
      }
    })
    .join(" ");
};

export default PaginatedTableView;
