import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import LoadableView from "components/common/containers/LoadableView";
import Rows from "components/common/containers/Rows";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import Toggle from "components/common/forms/Toggle";
import Icon from "components/common/Icon";
import useSctionDetails from "hooks/section-details/useSectionDetails";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface SectionDetailsPanelProps {
  className?: string;
}

const SectionDetailsPanel: React.FC<SectionDetailsPanelProps> = ({
  className,
}) => {
  const apiClient = useApiClient();
  const { details: proposalDetails } = useProposalData();
  const [isLoadingPastProposal, setIsLoadingPastProposal] = useState(false);
  const [useFixtures, setUseFixtures] = useState(false);
  const {
    id,
    name,
    headingLevel,
    pastProposal: sectionPastProposal,
    setPastProposal,
    details: sectionDetails,
    setDetails,
  } = useSctionDetails();
  const { isStaff } = useAuthenticatedUser();

  const [details, setLocalDetails] = useState(sectionDetails);
  const [pastProposal, setLocalPastProposal] = useState(sectionPastProposal);

  useEffect(() => {
    setLocalDetails(sectionDetails);
  }, [sectionDetails]);

  useEffect(() => {
    setLocalPastProposal(sectionPastProposal);
  }, [sectionPastProposal]);

  if (id === "") {
    return (
      <CenteredContainer className={className}>
        <Rows className="items-center gap-md text-secondary">
          <Icon name="memo-circle-info" size="xlarge" />
          <h1 className="font-semibold">Section Details</h1>
          <p>Select a section in the editor to view details about it.</p>
        </Rows>
      </CenteredContainer>
    );
  }

  const handleRefreshPastProposal = async () => {
    try {
      setIsLoadingPastProposal(true);
      const response = await apiClient.rfp.rfpProposalRelevantContextCreate(
        proposalDetails.id,
        { name }
      );
      if (response.data.relevant_context) {
        setPastProposal(response.data.relevant_context);
      }
    } catch (e) {
      toast.error(
        "Failed to refresh past proposals: " + message_from_exception(e)
      );
    } finally {
      setIsLoadingPastProposal(false);
    }
  };

  const handleOpenSearch = () => {
    let url = "/admin/search";
    url += `?query=${encodeURIComponent(name)}`;
    url += `&strategy=forced_paragraphs`;
    url += `&org=${proposalDetails.orgId}`;
    url += `&topic=${proposalDetails.topic || "null"}`;
    window.open(url, "_blank");
  };

  const handleGenerate = async () => {
    try {
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: useFixtures,
          heading_level: headingLevel,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  };

  const handleGenerateOutline = async () => {
    try {
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: useFixtures,
          outline_only: true,
          heading_level: headingLevel,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  };

  return (
    <Rows className={cn("p-md gap-md grow", className)}>
      <h1 className="font-semibold text-lg shrink-0">{name}</h1>
      <LabeledFormInput
        label="Details"
        className="grow shrink-0 basis-0 overflow-hidden"
      >
        <NaturalHeightTextArea
          className="grow resize-none"
          value={details}
          onChange={(text) => setDetails(text)}
        />
      </LabeledFormInput>
      <LoadableView
        isLoading={isLoadingPastProposal}
        className="grow shrink-0 basis-0 flex overflow-hidden"
      >
        <LabeledFormInput
          label={`Past Proposal (topic: ${proposalDetails.topic || "none"})`}
          className="grow relative"
        >
          <NaturalHeightTextArea
            className="grow resize-none"
            value={pastProposal}
            onChange={(text) => setPastProposal(text)}
          />
          <div className="absolute top-0 right-0 flex gap-md">
            <Button
              tooltip="Open in search admin"
              icon="magnifying-glass"
              onClick={handleOpenSearch}
            />
            <Button
              tooltip="Regenerate past proposal content"
              icon="refresh"
              onClick={handleRefreshPastProposal}
            />
          </div>
        </LabeledFormInput>
      </LoadableView>
      <Columns className="justify-end shrink-0">
        <Button
          variant="solid-secondary"
          icon="play"
          text="Outline"
          onClick={handleGenerateOutline}
        />
        <div className="grow" />
        {isStaff && (
          <Toggle
            on={useFixtures}
            onToggle={setUseFixtures}
            text="Use Fixture"
            toggleType="checkbox"
          />
        )}
        <Button
          variant="solid"
          icon="play"
          text="Generate"
          onClick={handleGenerate}
        />
      </Columns>
    </Rows>
  );
};

export default SectionDetailsPanel;
