import { useApiClient } from "providers/ApiClientProvider";
import usePaginatedData, { PaginatedData } from "./usePaginatedData";
import { useCallback, useState } from "react";
import { OverviewList, PersonList, ProjectDetails, ProjectList } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import { useAlert } from "providers/AlertProvider";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

export interface Project {
  id: string;
  title: string;
  customer: string;

  location: string;
  startMonth: number | null;
  startYear: number | null;
}

export interface Person {
  id: string;
  name: string;
  title: string;
}

export interface Overview {
  id: string;
  topic: string;
  created_at: string;
}

export type LibraryItem = Project | Person | Overview;
export type Category = "projects" | "people" | "overviews";

interface ContentLibrary {
  // The currently selected high level category
  activeCategory: Category;

  setActiveCategory: (category: Category) => void;

  // The items of the currently selected category (e.g. projects)
  activeItems: LibraryItem[];
  activePaginatedData: PaginatedData;

  // The currently selected item
  selectedItem: LibraryItem | null;
  selectedItemSaveState: SaveState;

  setSelectedItem: (item: LibraryItem | null) => void;
  setSelectedItemSaveState: (state: SaveState) => void;

  // Add a new item to the currently selected category
  addItem: () => void;
  removeItem: (item: LibraryItem) => void;
  updateItem: (item: LibraryItem) => void;
}

const useContentLibrary = (
  initialCategory: Category | undefined,
  initialSelectedItemId: string | undefined
): ContentLibrary => {
  const apiClient = useApiClient();
  const alert = useAlert();
  const [activeCategory, setActiveCategory] = useState<Category>(
    initialCategory ?? "projects"
  );
  const [selectedItemSaveState, setSelectedItemSaveState] =
    useState<SaveState>("unchanged");
  const [items, setItems, paginatedData] = usePaginatedData<LibraryItem, any>({
    endpoint: (options) => {
      switch (activeCategory) {
        case "projects":
          return apiClient.rfp.rfpProjectList(options);
        case "people":
          return apiClient.rfp.rfpPersonList(options);
        case "overviews":
          return apiClient.rfp.rfpOverviewList(options);
      }
    },
    map: (project) => {
      return itemFromRemote(project, activeCategory);
    },
    deps: [activeCategory],
  });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(
    initialSelectedItemId ?? null
  );

  const addItem = useCallback(async () => {
    try {
      switch (activeCategory) {
        case "projects":
          const result = await apiClient.rfp.rfpProjectCreateCreate();
          const item = itemFromRemote(result.data, activeCategory);
          setItems((prev) => [item, ...(prev || [])]);
          setSelectedItemId(item.id);
          break;
        case "people":
          const personResult = await apiClient.rfp.rfpPersonCreateCreate();
          const person = itemFromRemote(personResult.data, activeCategory);
          setItems((prev) => [person, ...(prev || [])]);
          setSelectedItemId(person.id);
          break;
        case "overviews":
          const overviewResult = await apiClient.rfp.rfpOverviewCreateCreate();
          const overview = itemFromRemote(overviewResult.data, activeCategory);
          setItems((prev) => [overview, ...(prev || [])]);
          setSelectedItemId(overview.id);
          break;
      }
    } catch (e) {
      toast.error("Failed to create item: " + message_from_exception(e));
    }
  }, [apiClient.rfp, activeCategory, setItems, setSelectedItemId]);

  const removeItem = useCallback(
    async (item: LibraryItem) => {
      try {
        switch (activeCategory) {
          case "projects":
            if (!item.id) return;
            await apiClient.rfp.rfpProjectDelete(item.id);
            break;
          case "people":
            if (!item.id) return;
            await apiClient.rfp.rfpPersonDelete(item.id);
            break;
          case "overviews":
            if (!item.id) return;
            await apiClient.rfp.rfpOverviewDelete(item.id);
            break;
        }
        setItems((prev) => {
          if (!prev) return null;
          return prev.filter((i) => i.id !== item.id);
        });
        setSelectedItemId(null);
      } catch (e) {
        toast.error("Failed to delete item: " + message_from_exception(e));
      }
    },
    [activeCategory, apiClient.rfp, setItems, setSelectedItemId]
  );

  const updateItem = useCallback(
    async (item: LibraryItem) => {
      setItems((prev) => {
        if (!prev) return null;
        return prev.map((i) => (i.id === item.id ? item : i));
      });
    },
    [setItems]
  );

  const setSelectedItem = useCallback(
    (item: LibraryItem | null) => {
      if (
        selectedItemSaveState === "saving" ||
        selectedItemSaveState === "error"
      ) {
        alert("The current project is still saving");
        return;
      }

      setSelectedItemId(item?.id ?? null);
      setSelectedItemSaveState("unchanged");
    },
    [alert, selectedItemSaveState]
  );

  const selectedItem = items?.find((i) => i.id === selectedItemId) ?? null;

  return {
    activeCategory: activeCategory,
    activeItems: items || [],
    activePaginatedData: paginatedData,
    selectedItem,
    selectedItemSaveState,
    updateItem,
    setSelectedItemSaveState,
    setActiveCategory,
    setSelectedItem,
    addItem,
    removeItem,
  };
};

const projectFromRemote = (remote: ProjectList): Project => {
  return {
    id: remote.id!,
    title: remote.title ?? "",
    customer: remote.customer ?? "",
    location: remote.location ?? "",
    startMonth: remote.start_month ?? null,
    startYear: remote.start_year ?? null,
  };
};

const personFromRemote = (remote: PersonList): Person => {
  return {
    id: remote.id!,
    name: remote.name ?? "",
    title: remote.title ?? "",
  };
};

const overviewFromRemote = (remote: OverviewList): Overview => {
  return {
    id: remote.id!,
    topic: remote.topic ?? "",
    created_at: remote.created_at!,
  };
};

const itemFromRemote = (
  remote: ProjectDetails,
  category: Category
): LibraryItem => {
  switch (category) {
    case "projects":
      return projectFromRemote(remote);
    case "people":
      return personFromRemote(remote);
    case "overviews":
      return overviewFromRemote(remote);
    default:
      throw new Error(`Unknown category: ${category}`);
  }
};

export default useContentLibrary;
