import { TElement, useEditorRef } from "@udecode/plate-common";
import { ReactEditor } from "slate-react";

export const useIsInTable = (element: TElement) => {
  const editor = useEditorRef();
  if (editor.id === "nested") return true;

  // If the element is a table, return false (we're not in a table, we are a table)
  if (element.type === "table") return false;

  const path = ReactEditor.findPath(editor as any, element);

  if (!path) return false;
  if (path.length === 0) return false;

  const rootPath = [path[0]];
  const [rootElement] = editor.node(rootPath);
  return rootElement && "type" in rootElement && rootElement.type === "table";
};
