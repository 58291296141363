import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from "react";
import OrgUserProvider from "./OrgUserProvider";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { posthog } from "posthog-js";
import { ProposalDetails } from "types/ProposalDetails";
import { ProposalParticipant } from "types/ProposalParticipant";
import { ProposalMetadata } from "hooks/useProposalDetails";
import useStyleGuide, { StyleGuideRenderer } from "lib/plate/useStyleGuide";

interface ProposalRequirementContextData {
  details: ProposalDetails;
  setDetails: Dispatch<SetStateAction<ProposalDetails>>;

  participants: ProposalParticipant[];
  setParticipants: Dispatch<SetStateAction<ProposalParticipant[]>>;

  metadata: ProposalMetadata;
  setMetadata: Dispatch<SetStateAction<ProposalMetadata>>;

  provider: HocuspocusProvider | null;

  styleGuide: StyleGuideRenderer;
}

const ProposalDetailsContext =
  createContext<ProposalRequirementContextData | null>(null);

interface ProposalDetailsProviderParams {
  children?: ReactNode;

  details: ProposalDetails;
  setDetails: Dispatch<SetStateAction<ProposalDetails>>;

  metadata: ProposalMetadata;
  setMetadata: Dispatch<React.SetStateAction<ProposalMetadata>>;

  participants: ProposalParticipant[];
  setParticipants: Dispatch<SetStateAction<ProposalParticipant[]>>;
}

export const ProposalDetailsProvider: React.FC<
  ProposalDetailsProviderParams
> = ({
  children,
  details,
  metadata,
  setDetails,
  setMetadata,
  participants,
  setParticipants,
}) => {
  const yjsProvider = useRef<HocuspocusProvider | null>(null);
  const styleGuide = useStyleGuide(details.styleGuide ?? {});
  // Undefined means the requirement is not found
  // Null means no requirement is selected

  useEffect(() => {
    if (details.id) {
      posthog.group("proposal", details.id);
    } else {
      posthog.group("proposal", "");
    }
    return () => {
      posthog.group("proposal", "");
    };
  }, [details.id]);

  useEffect(() => {
    if (metadata.title) {
      document.title = "Odo - " + metadata.title;
    } else {
      document.title = "Odo";
    }
    return () => {
      document.title = "Odo";
    };
  }, [metadata.title]);

  return (
    <ProposalDetailsContext.Provider
      value={{
        styleGuide,
        details,
        setDetails,
        participants,
        setParticipants,
        metadata,
        setMetadata,
        provider: yjsProvider.current,
      }}
    >
      <OrgUserProvider>{children}</OrgUserProvider>
    </ProposalDetailsContext.Provider>
  );
};

export const useProposalData = (): ProposalRequirementContextData => {
  const data = useContext(ProposalDetailsContext);
  if (!data) {
    return {
      styleGuide: new StyleGuideRenderer({
        text_styles: {
          default: {
            font: "Inter",
            font_size: 12,
            font_weight: "normal",
            margin_top: 12,
            margin_bottom: 0,
            padding_top: 0,
            padding_bottom: 0,
            padding_left: 0,
            padding_right: 0,
            line_spacing: 1.2,
          },
          p: {},
          p_list: {
            margin_bottom: 0,
            contextual_spacing: true,
          },
          h1: {
            font_size: 50,
            font_weight: "bold",
            margin_top: 20,
            margin_bottom: 6,
            background_color: "#FF0000",
          },
          h2: {
            color: "primary",
            font_size: 16,
            font_weight: "bold",
            margin_top: 18,
            margin_bottom: 6,
          },
          h3: {
            font_size: 14,
            font_weight: "bold",
            margin_top: 16,
            margin_bottom: 4,
          },
          h4: {
            color: "#666666",
            font_size: 12,
            font_weight: "bold",
            margin_top: 14,
            margin_bottom: 4,
          },
          h5: {
            font_size: 12,
            color: "#666666",
            font_weight: "normal",
            margin_top: 12,
            margin_bottom: 4,
          },
          h6: {
            margin_top: 12,
            font_weight: "normal",
            character_style: "italic",
            color: "#666666",
          },
        },
      }),
      details: {
        id: "test",
        rfpId: "",
        isPublished: false,
        orgId: "",
        participants: [],
        instructions: "",
        styleGuide: {},
        topic: "",
      },
      setDetails: () => {},
      participants: [],
      setParticipants: () => {},
      metadata: {
        title: "Test Proposal",
        archivedRequirements: [],
        outline: [],
        dueDate: null,
      },
      setMetadata: () => {},
      provider: null,
    };
  }
  return data;
};
