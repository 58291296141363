import { OverviewDetails } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface OverviewDetailsViewProps {
  overviewId: string;
  onChanged: (person: OverviewDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  remove: () => void;
}

const OverviewDetailsView: FC<OverviewDetailsViewProps> = ({
  overviewId,
  onChanged,
  onSaveStateChanged,
  remove,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpOverviewRead(overviewId);
    return result.data;
  }, [overviewId]);

  return (
    <BaseItemDetailsView<OverviewDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpOverviewUpdate(overviewId, details);
      }}
      deleteItem={async (projectId) => {
        await apiClient.rfp.rfpOverviewDelete(projectId);
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      remove={remove}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Topic">
            <Input
              placeholder="The topic of this overview"
              value={details.topic}
              onChange={(e) =>
                setDetails({ ...details, topic: e.target.value })
              }
            />
          </LabeledFormInput>
          <LabeledFormInput label="Writeup" className="grow overflow-hidden">
            <CollaborativeTextArea
              placeholder="A detailed writeup of the company focusing on this topic"
              className="h-[600px]"
              docId={details.writeup_id!}
              key={details.writeup_id}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default OverviewDetailsView;
