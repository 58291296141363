import { RFPCategory } from "api/Api";
import Pill from "components/common/Pill";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import { cn } from "lib/utils";
import Columns from "components/common/containers/Columns";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedComboBox from "components/common/menus/PaginatedComboBox";
import Icon from "components/common/Icon";
import Rows from "components/common/containers/Rows";

interface RFPCategoriesDropDownProps {
  className?: string;
  categories: RFPCategory[];
  onChange: (categories: RFPCategory[]) => void;
  onSave?: () => void;
}

interface LocalRFPCategory {
  id: string;
  name: string;
  displayName: string;
}

const RFPCategoriesDropDown: FC<RFPCategoriesDropDownProps> = ({
  className,
  categories,
  onChange,
  onSave,
}) => {
  const apiClient = useApiClient();
  const [allCategories, , paginatedData] = usePaginatedData<
    LocalRFPCategory,
    RFPCategory
  >({
    endpoint: apiClient.rfp.rfpCategoryList,
    map: (remote) => ({
      id: remote.name,
      name: remote.name,
      displayName: remote.display_name,
    }),
  });

  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (!open && onSave) {
          onSave();
        }
      }}
    >
      <DropdownMenuTrigger className={cn("overflow-x-hidden", className)}>
        <Columns>
          {categories.map((c) => (
            <Pill key={c.name} text={c.display_name} />
          ))}
          {categories.length === 0 && (
            <Pill className="text-secondary" text="No categories" />
          )}
        </Columns>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <PaginatedComboBox
          results={allCategories}
          paginatedData={paginatedData}
          renderResult={(category) => (
            <Columns className="items-center">
              <div className="w-[24px]">
                {categories.find((value) => value.name === category.name) && (
                  <Icon name="check" />
                )}
              </div>
              <Rows>{category.displayName}</Rows>
            </Columns>
          )}
          autoFocus={true}
          alwaysShowResults={true}
          onSelect={(category) => {
            const isSelected = categories.find(
              (value) => value.name === category.name
            );
            if (isSelected) {
              onChange(
                categories.filter((value) => value.name !== category.name)
              );
            } else {
              onChange([
                ...categories,
                { name: category.name, display_name: category.displayName },
              ]);
            }
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RFPCategoriesDropDown;
