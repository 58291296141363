import { PersonDetails } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface PersonDetailsViewProps {
  personId: string;
  onChanged: (person: PersonDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  remove: () => void;
}

const PersonDetailsView: FC<PersonDetailsViewProps> = ({
  personId,
  onChanged,
  onSaveStateChanged,
  remove,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpPersonRead(personId);
    return result.data;
  }, [personId]);

  return (
    <BaseItemDetailsView<PersonDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpPersonUpdate(personId, details);
      }}
      deleteItem={async (projectId) => {
        await apiClient.rfp.rfpPersonDelete(projectId);
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      remove={remove}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Name">
            <Input
              placeholder="The name of this person"
              value={details.name}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
            />
          </LabeledFormInput>
          <LabeledFormInput label="Title">
            <Input
              placeholder="What this person's title is"
              value={details.title}
              onChange={(e) =>
                setDetails({ ...details, title: e.target.value })
              }
            />
          </LabeledFormInput>
          <LabeledFormInput label="Bio" className="grow overflow-hidden">
            <CollaborativeTextArea
              placeholder="A detailed description of this person"
              className="h-[600px]"
              docId={details.bio_id!}
              key={details.bio_id}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default PersonDetailsView;
