import Columns from "components/common/containers/Columns";
import NavigationView from "components/common/NavigationView";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useContentLibrary from "hooks/useContentLibrary";
import ContentLibraryNavigation from "components/content-library/ContentLibraryNavigation";
import ContentLibraryProjectDetails from "components/content-library/ContentLibraryDetails";

const KnowledgeBaseRoute = () => {
  const { category, itemId } = useParams();
  const navigate = useNavigate();
  const {
    activeCategory,
    activeItems,
    activePaginatedData,
    selectedItem,
    setActiveCategory,
    setSelectedItemSaveState,
    setSelectedItem,
    addItem,
    updateItem,
    removeItem,
  } = useContentLibrary(category as any, itemId);

  useEffect(() => {
    if (selectedItem) {
      navigate(`/knowledge/${activeCategory}/${selectedItem.id}/`);
    } else {
      navigate(`/knowledge/${activeCategory}/`);
    }
  }, [activeCategory, navigate, selectedItem]);

  return (
    <NavigationView
      selected="knowledge-base"
      containerClassName="max-w-[1600px] w-full mx-auto"
      scrollable={false}
      fullWidth={true}
    >
      <h1 className="text-2xl font-semibold shrink-0 mb-md">Knowledge Base</h1>
      <Columns className="grow border rounded-md">
        <ContentLibraryNavigation
          items={activeItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          paginatedData={activePaginatedData}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          addItem={addItem}
        />
        <ContentLibraryProjectDetails
          className="border-l"
          activeCategory={activeCategory}
          selectedItem={selectedItem}
          setSelectedItemSaveState={setSelectedItemSaveState}
          updateItem={updateItem}
          removeItem={removeItem}
        />
      </Columns>
    </NavigationView>
  );
};

export default KnowledgeBaseRoute;
