import Button from "components/common/Button";
import LoadableView from "components/common/containers/LoadableView";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import {
  Category,
  LibraryItem,
  Overview,
  Person,
  Project,
} from "hooks/useContentLibrary";
import { PaginatedData } from "hooks/usePaginatedData";
import { cn, format_absolute_date } from "lib/utils";

interface ContentLibraryNavigationProps {
  items: LibraryItem[];
  paginatedData: PaginatedData;
  selectedItem: LibraryItem | null;
  setSelectedItem: (item: LibraryItem) => void;
  addItem: () => void;
  activeCategory: Category;
  setActiveCategory: (category: Category) => void;
}

const ContentLibraryNavigation: React.FC<ContentLibraryNavigationProps> = ({
  items,
  paginatedData,
  selectedItem,
  setSelectedItem,
  addItem,
  activeCategory,
  setActiveCategory,
}) => {
  const selectedClassName = "bg-primary hover:bg-primary text-background";
  const unselectedClassName =
    "py-sm px-lg hover:bg-background-selected cursor-pointer";

  const singularPropertyName = (() => {
    switch (activeCategory) {
      case "projects":
        return "Project";
      case "people":
        return "Person";
      case "overviews":
        return "Overview";
    }
  })();

  return (
    <>
      {/*********************** Categories ***********************/}
      <Rows className="bg-background-secondary border-r">
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "overviews" && selectedClassName
          )}
          onClick={() => setActiveCategory("overviews")}
        >
          Overviews
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "projects" && selectedClassName
          )}
          onClick={() => setActiveCategory("projects")}
        >
          Projects
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "people" && selectedClassName
          )}
          onClick={() => setActiveCategory("people")}
        >
          People
        </div>
      </Rows>
      {/*********************** Subcategories ***********************/}
      <LoadableView
        className="grow basis-0 flex"
        isLoading={paginatedData.status === "loading"}
      >
        <Rows className="grow">
          <PaginatedTableView
            results={items}
            variant="without-padding"
            paginatedData={paginatedData}
            searchable={true}
            columns={1}
            className="grow basis-0 min-w-[200px] border-b"
            rowSeparators={true}
            renderRow={(item, Cell, Row) => {
              let title: string;
              let subtitle = "-";
              switch (activeCategory) {
                case "projects":
                  const project = item as Project;
                  title = project.title;
                  if (project.startMonth && project.startYear) {
                    title += ` (${project.startMonth}/${project.startYear})`;
                  }
                  if (project.customer && project.location) {
                    subtitle = `${project.customer} - ${project.location}`;
                  } else if (project.customer) {
                    subtitle = project.customer;
                  } else if (project.location) {
                    subtitle = project.location;
                  }
                  break;
                case "people":
                  const person = item as Person;
                  title = person.name;
                  if (person.title) {
                    subtitle = person.title;
                  }
                  break;
                case "overviews":
                  const overview = item as Overview;
                  title = overview.topic;
                  if (overview.created_at) {
                    subtitle = format_absolute_date(overview.created_at);
                  }
                  break;
              }
              return (
                <Row key={item.id!}>
                  <Cell
                    className={cn(
                      "px-lg hover:bg-background-selected cursor-pointer",
                      !title && "text-secondary",
                      selectedItem?.id === item.id && "bg-background-selected"
                    )}
                    onClick={() => setSelectedItem(item)}
                  >
                    <Rows>
                      <div>{title || `New ${singularPropertyName}`}</div>
                      <div className="text-sm text-secondary">{subtitle}</div>
                    </Rows>
                  </Cell>
                </Row>
              );
            }}
          />
          <Button
            text={`Add ${singularPropertyName}`}
            icon="plus"
            variant="solid-secondary"
            className="py-sm m-md"
            onClick={addItem}
          />
        </Rows>
      </LoadableView>
    </>
  );
};

export default ContentLibraryNavigation;
