import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import Icon from "components/common/Icon";
import { Category, LibraryItem, Project } from "hooks/useContentLibrary";
import { cn } from "lib/utils";
import { ReactNode } from "react";
import ProjectDetailsView from "./ProjectDetailsView";
import PersonDetailsView from "./PeopleDetailsView";
import OverviewDetailsView from "./OverviewDetailsView";
import Scrollable from "components/common/containers/Scrollable";

interface ContentLibraryProjectDetailsProps {
  activeCategory: Category;
  selectedItem: LibraryItem | null;
  setSelectedItemSaveState: (state: SaveState) => void;
  updateItem: (item: LibraryItem) => void;
  removeItem: (item: LibraryItem) => void;
  className?: string;
}

const ContentLibraryProjectDetails: React.FC<
  ContentLibraryProjectDetailsProps
> = ({
  activeCategory,
  selectedItem,
  setSelectedItemSaveState: setSelectedProjectSaveState,
  updateItem,
  removeItem,
  className,
}) => {
  let content: ReactNode = null;
  if (selectedItem) {
    switch (activeCategory) {
      case "projects":
        content = (
          <ProjectDetailsView
            key={selectedItem.id}
            projectId={selectedItem.id}
            remove={() => {
              removeItem(selectedItem);
            }}
            onChanged={(project) =>
              updateItem({
                id: project.id!,
                title: project.title ?? "",
                customer: project.customer ?? "",
                location: project.location ?? "",
                startMonth: project.start_month ?? null,
                startYear: project.start_year ?? null,
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "people":
        content = (
          <PersonDetailsView
            key={selectedItem.id}
            personId={selectedItem.id}
            remove={() => {
              removeItem(selectedItem);
            }}
            onChanged={(person) =>
              updateItem({
                id: person.id!,
                name: person.name ?? "",
                title: person.title ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "overviews":
        content = (
          <OverviewDetailsView
            key={selectedItem.id}
            overviewId={selectedItem.id}
            remove={() => {
              removeItem(selectedItem);
            }}
            onChanged={(overview) =>
              updateItem({
                id: overview.id!,
                topic: overview.topic ?? "",
                created_at: overview.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
    }
  } else {
    return <NoItemSelected />;
  }

  return <Rows className={cn("basis-0 grow-[2]", className)}>{content}</Rows>;
};
const NoItemSelected = () => {
  return (
    <CenteredContainer className="border-l basis-0 grow-[2]">
      <Rows className="items-center gap-md text-secondary">
        <Icon name="square-left" size="xlarge" variant="solid" />
        <p className="text-center">
          Select an item
          <br />
          from the list on the left
        </p>
      </Rows>
    </CenteredContainer>
  );
};

export default ContentLibraryProjectDetails;
