import { cva, VariantProps } from "class-variance-authority";

import { cn } from "lib/utils";
import Icon, { IconName } from "../Icon";
import { useState } from "react";
import Button from "../Button";
import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  icon?: IconName;
  valueClearable?: boolean;
  onClear?: () => void;
}

export const inputVariants = cva("border w-full rounded-md bg-popover", {
  variants: {
    variant: {
      default: "p-sm text-lg px-2m",
      menu: "px-sm py-xs text-sm px-2m",
      form: "rounded-sm tablet:text-md text-lg p-sm px-2m",
      accent:
        "bg-background-selected border-0 px-2m py-sm focused:border-primary",
      undecorated: "p-0 border-none",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { icon, className, variant, type, onClear, valueClearable, ...props },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [focused, setFocused] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    let resolvedType: string | undefined = type;
    if (type === "password") {
      resolvedType = passwordVisible ? "text" : "password";
    }

    let endButton: React.ReactNode | undefined = undefined;
    if (type === "password") {
      endButton = (
        <Button
          type="button"
          className="flex items-center justify-center w-md text-secondary"
          onClick={togglePasswordVisibility}
          icon={passwordVisible ? "eye-slash" : "eye"}
        />
      );
    } else if (valueClearable && props.value) {
      endButton = (
        <Button
          type="button"
          className="flex items-center justify-center w-md text-secondary"
          onClick={() => {
            props.onChange?.({ target: { value: "" } } as any);
            onClear?.();
          }}
          icon="circle-xmark"
          iconVariant="solid"
        />
      );
    }

    return (
      <div
        className={cn(
          "flex flex-row gap-md items-center",
          inputVariants({ variant }),
          focused && "border-primary",
          focused &&
            variant === "accent" &&
            "outline outline-primary bg-background",
          props.readOnly && "bg-background-selected",
          className
        )}
      >
        {icon && <Icon size="small" className="text-secondary" name={icon} />}
        <input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className={cn(
            "w-full bg-popover",
            props.readOnly && "text-secondary bg-background-selected",
            variant === "accent" && "bg-background-selected",
            variant === "accent" && focused && "bg-background"
          )}
          type={resolvedType}
          ref={ref}
          {...props}
        />
        {endButton}
      </div>
    );
  }
);
Input.displayName = "Input";

export default Input;
